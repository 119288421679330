<template>
	<!-- <b-button variant="outline-primary" size="sm">
		<slot></slot>
	</b-button> -->
  <button class="wizard-btn" tabindex="-1" type="button">
    <slot></slot>
  </button>
</template>
<script>
  import { BButton } from 'bootstrap-vue'
  export default {
	components: { BButton },
  }
</script>
<style>
</style>
