<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>
    <div :hidden="!hiddenLoader">
      <b-card>
        <!-- form start -->
        <validation-observer
          ref="addForm"
          class="d-flex justify-content-center"
        >
          <b-form class="col" @submit.prevent>
            <form-wizard
              ref="form_wizard"
              back-button-text="Précédent"
              nextButtonText="Suivant"
              color="#27295c"
              class="steps-transparent"
              @tabIndexChanged="activeTabIndex"
              :validateOnBack="false"
              :title="null"
              :subtitle="null"
              :startIndex="startIndex"
            >
              <!-- Informations générales tab start -->
              <tab-content
                title="Informations générales"
                :before-change="validationFormInfoGenerale"
              >
                <validation-observer ref="infoGeneraleRules" tag="form">
                  <b-row class="mb-0">
                    <!-- Nom association start -->
                    <b-form-group class="col ml-1 mr-2">
                      <validation-provider
                        #default="{ errors }"
                        name="Nom"
                        rules="required"
                      >
                        <label for="name">Nom Association</label>
                        <b-form-input
                          v-model="associationForm.etape1.nameAssociation"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Nom Association"
                          @click="resetErrorInputNameAssociation"
                          trim
                        />
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : errorsNameAssociation
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                     
                    <!-- Nom association end -->

                    <!-- Code association start -->
                    <b-form-group class="col mr-2">
                      <validation-provider
                        #default="{ errors }"
                        name="code"
                        rules="required"
                      >
                        <label for="code">Code Association</label>
                        <b-form-input
                          v-model="associationForm.etape1.codeAssociation"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Code Association"
                          @click="resetErrorInputCodeAssociation"
                          trim
                        />
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : errorsCodeAssociation
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Code association end -->
                  </b-row>
                  <b-row>
                    <b-form-group class="hidden">
                        <validation-provider
                          #default="{ errors }"
                          name="existing"
                          rules="required"
                        >
                          <b-form-input
                            id="verifTotal"
                            v-model="controlOverExistingAssociation"
                            placeholder="Total"
                            :state="errors.length > 0 ? false : null"
                          />
                        </validation-provider>
                      </b-form-group>
                      <b-alert class="ml-3" :show="!controlOverExistingAssociation" variant="danger">
                        <div class="alert-body d-flex flex justify-space-between">
                          <span class="mr-auto"
                            >Le nom ou le code de cette association existe déjà
                          </span>
                        </div>
                      </b-alert>
                  </b-row> 
                  <b-row>
                    <!-- associes start -->
                    <div class="col ml-1">
                      <div class="mb-1">
                        <b-card-text><strong>Associés :</strong></b-card-text>
                      </div>

                      <b-row
                        v-for="(item, index) in itemsAssocies"
                        :key="index"
                        ref="row"
                      >
                        <b-form-group class="col pr-0">
                          <validation-provider
                              #default="{ errors }"
                              name="nomAssocies"
                              rules="required"
                          >
                            <label for="compte">Nom & Prénoms</label>
                          <b-form-select
                                id="compte"
                                v-model="item.nom_associe"
                                :options="associes"
                                @input="checkNomAssocies(index)"
                                value-field="name"
                                text-field="name"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{
                              errorCheckNomAssocies[index]
                                ? errorCheckNomAssocies[index]
                                : ""
                            }}</small>
                            <small class="text-danger">{{
                              errors[0]
                                ? errors[0]
                                : item.errorNomAssocies
                                ? item.errorNomAssocies
                                : ""
                            }}</small>

                          </validation-provider>
                        </b-form-group>
<!--                        <b-form-group class="col-11">
                          <validation-provider
                            #default="{ errors }"
                            name="nom associé"
                            rules="required"
                          >
                            <label for="nomAssocies">Nom & Prénoms</label>
                            &lt;!&ndash; <b-form-input
                              id="nomAssocies"
                              v-model="item.nom_associe"
                              @input="checkNomAssocies(index)"
                              placeholder="Nom & Prénoms"
                              :state="errors.length > 0 ? false : null"
                              trim
                            /> &ndash;&gt;
                            <b-form-input
                              list="nomAssocies"
                              v-model="item.nom_associe"
                              placeholder="Nom & Prénoms"
                              @input="checkNomAssocies(index)"
                              :state="errors.length > 0 ? false : null"
                              trim
                            />

                            <b-form-datalist
                              id="nomAssocies"
                              :options="associes"
                            >
                            </b-form-datalist>
                            <small class="text-danger">{{
                              errorCheckNomAssocies[index]
                                ? errorCheckNomAssocies[index]
                                : ""
                            }}</small>
                            <small class="text-danger">{{
                              errors[0]
                                ? errors[0]
                                : item.errorNomAssocies
                                ? item.errorNomAssocies
                                : ""
                            }}</small>
                          </validation-provider>
                        </b-form-group>-->

                        <!-- Remove Button start -->
                        <div
                          v-if="showRemoveItemAssocies > 1"
                          class="mt-1 mr-1"
                        >
                          <b-button
                            variant="outline-danger"
                            class="p-0 mt-2"
                            @click="removeItemAssocies(index)"
                          >
                            <feather-icon icon="XIcon" />
                            <span></span>
                          </b-button>
                        </div>
                        <!-- Remove Button end -->
                      </b-row>

                      <!-- Add Button start -->
                      <b-button
                        size="sm"
                        variant="primary"
                        :disabled="disabledButtonAssocies"
                        @click="repeateAgainAssocies"
                        class="my-1"
                      >
                        <b-icon-plus></b-icon-plus>
                        AJOUTER
                      </b-button>
                      <!-- Add Button start -->
                    </div>
                    <!-- associes end -->

                    <!-- acheteur start -->
                    <div class="col ml-1">
                      <div class="mb-1">
                        <b-card-text><strong>Acheteurs :</strong></b-card-text>
                      </div>
                      <b-row
                        v-for="(item, index) in itemsAcheteurs"
                        :key="index"
                        ref="row"
                      >
                        <b-form-group class="col-11">
                          <validation-provider
                            #default="{ errors }"
                            name="nom acheteur"
                            rules="required"
                          >
                            <label for="nomAcheteur">Nom & Prénoms</label>
                            <b-form-input
                              id="nomAcheteur"
                              list="nomAcheteurs"
                              v-model="item.nom_acheteur"
                              placeholder="Nom & Prénoms"
                              @input="checkNomAcheteur(index)"
                              :state="errors.length > 0 ? false : null"
                              trim
                            />

                            <b-form-datalist
                              id="nomAcheteurs"
                              :options="
                                comptes.filter(
                                  (_acco) => _acco.author_type == 'acheteur'
                                )
                              "
                            >
                            </b-form-datalist>
                            <small class="text-danger">{{
                              errorCheckNomAcheteur[index]
                                ? errorCheckNomAcheteur[index]
                                : ""
                            }}</small>
                            <small class="text-danger">{{
                              errors[0]
                                ? errors[0]
                                : item.errorNomAcheteur
                                ? item.errorNomAcheteur
                                : ""
                            }}</small>
                          </validation-provider>
                        </b-form-group>

                        <!-- Remove Button start -->
                        <!-- v-if="showRemoveItemAcheteur > 1" -->
                        <div class="mt-1 mr-1">
                          <b-button
                            variant="outline-danger"
                            class="p-0 mt-2"
                            @click="removeItemAcheteur(index)"
                          >
                            <feather-icon icon="XIcon" />
                            <span></span>
                          </b-button>
                        </div>
                        <!-- Remove Button end -->
                      </b-row>

                      <!-- Add Button start -->
                      <b-button
                        size="sm"
                        variant="primary"
                        :disabled="disabledButtonAcheteur"
                        @click="repeateAgainAcheteur"
                        class="my-1"
                      >
                        <b-icon-plus></b-icon-plus>
                        AJOUTER
                      </b-button>
                      <!-- Add Button end -->
                    </div>
                    <!-- Acheteur end -->
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- Informations générales tab end -->

              <!-- Compte tab start -->
              <tab-content
                title="Comptes"
                :before-change="validationFormCompte"
              >
                <validation-observer ref="compteRules" tag="form">
                  <!-- Montant caisse start -->
                  <b-form-group class="col-12">
                    <validation-provider
                      #default="{ errors }"
                      name="Montant"
                      rules="required"
                    >
                      <label for="name">Montant caisse</label>
                      <b-input-group :append="devisePrincipal.abbreviation">
                        <cleave
                          class="form-control"
                          :raw="false"
                          placeholder="0"
                          :options="numberFormat"
                          v-model="associationForm.etape2.montant"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? errors[0] : errorsMontantCaisse
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- Montant caisse end -->

                  <!-- 
									** Liste des comptes **
								-->
                  <!-- compte acheteur start -->
                  <b-card class="block-text mt-1">
                    <div class="border-bottom pb-1 mb-2">
                      <span class="h4">ACHETEUR</span>
                    </div>

                    <b-row
                      v-for="(item, index) in compteAcheteurs"
                      :key="index"
                      ref="row"
                    >
                      <b-form-group class="col" disabled>
                        <validation-provider
                          #default="{ errors }"
                          name="libelle compte acheteur"
                          rules="required"
                        >
                          <label for="libelle_compte_acheteur"
                            >Libellé du compte</label
                          >
                          <b-form-input
                            id="libelle_compte_acheteur"
                            v-model="
                              compteAcheteurs[index].libelle_compte =
                                itemsAcheteurs[index].nom_acheteur
                            "
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger"> </small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group class="col" disabled>
                        <validation-provider
                          #default="{ errors }"
                          name="nom acheteur"
                          rules="required"
                        >
                          <label for="nom_acheteur">Nom acheteur</label>
                          <b-form-input
                            id="nom_acheteur"
                            v-model="
                              compteAcheteurs[index].nom_acheteur =
                                itemsAcheteurs[index].nom_acheteur
                            "
                            placeholder="Nom acheteur"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">
                            <!-- {{
                              errors[0]
                                ? errors[0]
                                : compteAcheteurs[index].errorsNomAcheteur
                                ? compteAcheteurs[index].errorsNomAcheteur
                                : ""
                            }} -->
                          </small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group class="col">
                        <validation-provider
                          #default="{ errors }"
                          name="solde initial acheteur"
                          rules="required"
                        >
                          <label for="solde_initial_acheteur"
                            >Solde initial</label
                          >
                          <cleave
                            id="solde_initial_acheteur"
                            class="form-control"
                            v-model="compteAcheteurs[index].solde_initial"
                            placeholder="0"
                            :raw="false"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger"> </small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group class="col">
                        <validation-provider
                          #default="{ errors }"
                          name="devise acheteur"
                          rules="required"
                        >
                          <label for="devise_acheteur">Devise</label>
                          <b-form-select
                            id="devise_acheteur"
                            v-model="compteAcheteurs[index].devise"
                            :options="devises"
                            :disabled="compteAcheteurs[index].deviseDisabled"
                            value-field="id"
                            text-field="name"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">
                            {{
                              errors[0]
                                ? errors[0]
                                : compteAcheteurs[index].errorsDeviseAcheteur
                                ? compteAcheteurs[index].errorsDeviseAcheteur
                                : ""
                            }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-row>
                  </b-card>
                  <!-- compte acheteur end -->

                  <!-- compte associés start -->
                  <b-card class="mt-1 block-text">
                    <div class="border-bottom pb-1 mb-2">
                      <span class="h4">ASSOCIÉS</span>
                    </div>

                    <b-row
                      v-for="(item, index) in comptesAssocies"
                      :key="index"
                      ref="row"
                    >
                      <b-form-group class="col">
                        <validation-provider
                          #default="{ errors }"
                          name="libelle compte associes"
                          rules="required"
                        >
                          <label for="libelle_compte_associes"
                            >Libellé du compte</label
                          >
                          <b-form-input
                            id="libelle_compte_associes"
                            @input="checkAssocieCompte(index)"
                            v-model="comptesAssocies[index].libelle_compte"
                            placeholder="Libellé du compte"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">
                            {{
                              errors[0]
                                ? errors[0]
                                : comptesAssocies[index]
                                    .errorsLibelleCompteAssocies
                                ? comptesAssocies[index]
                                    .errorsLibelleCompteAssocies
                                : ""
                            }}
                          </small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group class="col">
                        <validation-provider
                          #default="{ errors }"
                          name="nom associes"
                          rules="required"
                        >
                          <label for="nom_associes">Nom associés</label>
                          <b-form-select
                            id="nom_associes"
                            v-model="comptesAssocies[index].nom_associe"
                            :options="associesList"
                            value-field="name"
                            text-field="name"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">
                            <!-- {{
                              errors[0]
                                ? errors[0]
                                : compteAcheteurs[index].errorsNomAssocies
                                ? compteAcheteurs[index].errorsNomAssocies
                                : ""
                            }} -->
                          </small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group class="col">
                        <validation-provider
                          #default="{ errors }"
                          name="solde initial associes"
                          rules="required"
                        >
                          <label for="solde_initial_associes"
                            >Solde initial</label
                          >
                          <cleave
                            id="solde_initial_associes"
                            class="form-control"
                            v-model="comptesAssocies[index].solde_initial"
                            placeholder="0"
                            :raw="false"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">
                            <!-- {{
                              errors[0]
                                ? errors[0]
                                : compteAcheteurs[index]
                                    .errorsSoldeInitialAssocies
                                ? compteAcheteurs[index]
                                    .errorsSoldeInitialAssocies
                                : ""
                            }} -->
                          </small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group class="col">
                        <validation-provider
                          #default="{ errors }"
                          name="devise associes"
                          rules="required"
                        >
                          <label for="devise_associes">Devise</label>
                          <b-form-select
                            id="devise_associes"
                            v-model="comptesAssocies[index].devise"
                            :options="devises"
                            value-field="id"
                            text-field="name"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">
                            <!-- {{
                              errors[0]
                                ? errors[0]
                                : compteAcheteurs[index].errorsDeviseAssocies
                                ? compteAcheteurs[index].errorsDeviseAssocies
                                : ""
                            }} -->
                          </small>
                        </validation-provider>
                      </b-form-group>

                      <!-- Remove Button start -->
                      <div class="mt-1 mr-1">
                        <!-- v-if="showRemoveItemCompteAssocies > 1" -->
                        <b-button
                          variant="outline-danger"
                          class="p-0 mt-2"
                          @click="removeItemCompteAssocies(index)"
                        >
                          <feather-icon icon="XIcon" />
                          <span></span>
                        </b-button>
                      </div>
                      <!-- Remove Button end -->
                    </b-row>

                    <!-- Add Button start -->
                    <b-button
                      size="sm"
                      variant="primary"
                      :disabled="disabledButtonCompteAssocies"
                      @click="repeateAgainCompteAssocies"
                      class="my-1"
                    >
                      <b-icon-plus></b-icon-plus>
                      AJOUTER
                    </b-button>
                    <!-- Add Button end -->
                  </b-card>
                  <!-- compte associés end -->
                </validation-observer>
              </tab-content>
              <!-- Compte tab end -->

              <!-- Dette tab start -->
              <tab-content title="Dettes" :before-change="validationFormDette">
                <validation-observer ref="detteRules" tag="form">
                  <b-row class="mb-3">
                    <!-- Carte importateur start -->
                    <b-form-group class="col mr-2">
                      <validation-provider
                        #default="{ errors }"
                        name="ci"
                        rules="required"
                      >
                        <label for="ci">Carte importateur</label>
                        <b-input-group :append="deviseCarte.abbreviation">
                          <cleave
                            id="ci"
                            class="form-control"
                            v-model="associationForm.etape3.ci"
                            placeholder="0"
                            :raw="false"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                            @click="resetErrorInputCarteImportateur"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : errorsCarteImportateur
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Carte importateur end -->

                    <!-- Frais start -->
                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="frais"
                        rules="required"
                      >
                        <label for="frais">Frais</label>
                        <b-input-group :append="deviseFrais.abbreviation">
                          <cleave
                            id="frais"
                            class="form-control"
                            v-model="associationForm.etape3.frais"
                            placeholder="0"
                            :raw="false"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                            @click="resetErrorInputFrais"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : errorsFrais
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Frais end -->
                  </b-row>

                  <b-row
                    v-for="(item, index) in itemsChargeurs"
                    :key="index"
                    ref="row"
                  >
                    <!-- Chargeurs start -->
                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="chargeur"
                        rules="required"
                      >
                        <label for="chargeur">Chargeur</label>
                        <b-form-select
                          id="libelle"
                          v-model="item.chargeur"
                          :options="listeChargeurs"
                          disabled-field="isDisabled"
                          value-field="id"
                          text-field="name"
                          @change="refreshChargeur(item.chargeur, index)"
                          :state="errors.length > 0 ? false : null"
                          style="item.chargeur"
                        >
                          <template #first>
                            <b-form-select-option
                              class="text-primary font-weight-bold"
                              value="NOUVEAU_CHARGEUR"
                            >
                              <span class="">
                                <b-button block variant="primary">
                                  NOUVEAU CHARGEUR
                                </b-button>
                              </span>
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <!-- <b-form-input
                          id="chargeur"
                          v-model="item.chargeur_name"
                          placeholder="Chargeur"
                          :state="errors.length > 0 ? false : null"
                        /> -->
                        <small class="text-danger">
                          {{
                            errors[0]
                              ? errors[0]
                              : item.errorsChargeurName
                              ? item.errorsChargeurName
                              : ""
                          }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-2">
                      <validation-provider
                        #default="{ errors }"
                        name="devise"
                        rules=""
                      >
                        <label for="chargeur">Devise</label>
                        <b-button block variant="secondary">
                          {{ $thousandSeparator(getDeviseChargeur(item)) }}
                        </b-button>
                        <small class="text-danger">
                          {{ errors[0] ? errors[0] : "" }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Chargeurs end -->

                    <!-- Montant start -->
                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="montant"
                        rules="required"
                      >
                        <label for="montant">Montant</label>
                        <cleave
                          id="montant"
                          class="form-control"
                          v-model="item.montant"
                          placeholder="0"
                          :raw="false"
                          :options="numberFormat"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">
                          {{
                            errors[0]
                              ? errors[0]
                              : item.errorsMontant
                              ? item.errorsMontant
                              : ""
                          }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                    <div class="col-2 mb-50">
                      <b-button
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        block
                        @click="removeItem(index)"
                      >
                        Supprimer
                        <span></span>
                      </b-button>
                    </div>
                  </b-row>

                  <!-- Add Button start -->
                  <b-button variant="primary" @click="repeateAgain">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span>AJOUTER UN CHARGEUR</span>
                  </b-button>
                  <!-- Add Button end -->
                </validation-observer>
              </tab-content>
              <!-- Dette tab end -->

              <!-- Véhicule en stock tab start -->
              <!-- :before-change="validationFormVehiculeStock" -->
              <tab-content title="Véhicules en stock">
                <validation-observer ref="vehiculeStockRules" tag="form">
                  <b-row>
                    <b-col cols="3">
                      <label for="dev_achat_transport"
                        >Devise d'achat</label
                      >
                      <b-form-select
                        id="dev_achat_transport"
                        v-model="devisesManif.dev_achat"
                        :options="devises"
                        value-field="id"
                        text-field="name"
                        @change="checkDeviseAchat()"
                      />
                    </b-col>

                    <b-col cols="3">
                      <b-form-group class="col">
                          <validation-provider
                            #default="{ errors }"
                            name="taux conversion achat"
                            rules="required"
                          >
                            <label for="prix_achat">Taux Achat</label>
                            <b-form-input 
                                type="number"
                                id="taux_conversion_achat"
                                v-model="deviseAchat.value"
                                class="form-control"
                                @input="checkDeviseAchat()"
                                :disabled="deviseAchat.abbreviation === devisePrincipal.abbreviation"
                                :options="numberFormat"
                                :state="errors.length > 0 ? false : null"
                              >
                            </b-form-input>
                            <small class="text-danger">{{
                              errors[0] ? errors[0] : ""
                            }}</small>
                          </validation-provider>
                      </b-form-group>
                    </b-col>


                    <b-col cols="3">
                      <label for="dev_transport"
                        >Devise de transport</label
                      >
                      <b-form-select
                        id="dev_transport"
                        v-model="devisesManif.dev_transport"
                        :options="devises"
                        value-field="id"
                        text-field="name"
                        @change="checkDeviseTransport()"
                      />
                    </b-col>

                    <b-col cols="3">
                      <b-form-group class="col">
                          <validation-provider
                            #default="{ errors }"
                            name="taux conversion transport"
                            rules="required"
                          >
                            <label for="prix_achat">Taux Transport</label>
                            <b-form-input 
                                type="number"
                                id="taux_conversion_transport"
                                v-model="deviseTransport.value"
                                class="form-control"
                                @input="checkDeviseTransport()"
                                :disabled="deviseTransport.abbreviation === devisePrincipal.abbreviation"
                                :options="numberFormat"
                                :state="errors.length > 0 ? false : null"
                              >
                            </b-form-input>
                            <small class="text-danger">{{
                              errors[0] ? errors[0] : ""
                            }}</small>
                          </validation-provider>
                      </b-form-group>
                    </b-col>


                  </b-row>
                  <hr />
                  <b-row>
                    <b-form-group class="col pr-0">
                      <validation-provider
                          #default="{ errors }"
                          name="marque"
                          rules="required"
                      >
                        <label for="marque">Marque</label>
                        <combo-box-component
                      id="marque"
                      :dataSource="marquesList"
                      v-model="itemVehiculeStock.marque"
                      :state="errors.length > 0 ? false : null"
                  >
                  </combo-box-component>
                        <small class="text-dan  ger">{{
                            errors[0] ? errors[0] : ""
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="numero chassis"
                        rules="required"
                      >
                        <label for="numero_chassis">Chassis</label>
                        <b-form-input
                          id="numero_chassis"
                          type="text"
                          placeholder="Chassis"
                          v-model="itemVehiculeStock.numero_chassis"
                          :state="errors.length > 0 ? false : null"
                          trim
                        />

                        <small class="text-danger">{{
                          errors[0] ? errors[0] : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col pr-0">
                      <validation-provider
                          #default="{ errors }"
                          name="couleur"
                          rules="required"
                      >
                        <label for="couleur">Couleur</label>
                      <combo-box-component
                        id="couleur"
                        :dataSource="couleursList"
                        v-model="itemVehiculeStock.couleur"
                        :state="errors.length > 0 ? false : null"
                      >
                  </combo-box-component>
                        <small class="text-danger">{{
                            errors[0] ? errors[0] : ""
                          }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="annee"
                        rules="required"
                      >
                        <label for="annee">Année</label>
                        <b-form-input
                          id="annee"
                          type="number"
                          placeholder="Année"
                          v-model="itemVehiculeStock.annee"
                          :state="errors.length > 0 ? false : null"
                          trim
                        />
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>

                  <b-row>
                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="boite"
                        rules="required"
                      >
                        <label for="boite">Boîte</label>
                        <b-form-select
                          id="boite"
                          v-model="itemVehiculeStock.boite"
                          :options="boite_vitesses"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group class="col pr-0">
                      <validation-provider
                        #default="{ errors }"
                        name="cylindre"
                        rules="required"
                      >
                        <label for="cylindre">Cylindre</label>
                        <b-form-select
                          id="cylindre"
                          v-model="itemVehiculeStock.cylindre"
                          :options="cylindres"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="prix achat"
                        rules="required"
                      >
                        <label for="prix_achat">Achat</label>
                        <b-input-group :append="deviseAchat.abbreviation">
                          <cleave
                            id="prix_achat"
                            v-model="itemVehiculeStock.prix_achat"
                            class="form-control"
                            :raw="false"
                            placeholder="0"
                            @input="valeurStock()"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="prix carte importateur"
                        rules="required"
                      >
                        <label for="prix_carte_imp">Carte importateur</label>
                        <b-input-group :append="deviseCarte.abbreviation">
                          <cleave
                            id="prix_carte_imp"
                            v-model="itemVehiculeStock.prix_carte_imp"
                            class="form-control"
                            :raw="false"
                            @input="valeurStock()"
                            placeholder="0"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>

                  <b-row>
                    <b-form-group class="col-3">
                      <validation-provider
                        #default="{ errors }"
                        name="prix transport"
                        rules="required"
                      >
                        <label for="prix_transp">Transport</label>
                        <b-input-group :append="deviseTransport.abbreviation">
                          <cleave
                            id="prix_transp"
                            v-model="itemVehiculeStock.prix_transp"
                            class="form-control"
                            :raw="false"
                            @input="valeurStock()"
                            placeholder="0"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-3">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="frais"
                      >
                        <label for="frais">Frais</label>
                        <b-input-group :append="deviseFrais.abbreviation">
                          <cleave
                            id="frais"
                            v-model="itemVehiculeStock.frais"
                            class="form-control"
                            :raw="false"
                            placeholder="0"
                            @input="valeurStock()"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : ""
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col-3">
                      <validation-provider
                        #default="{ errors }"
                        name="mtdepense"
                      >
                        <label for="mtdepense">Dépenses</label>
                        <b-input-group :append="devisePrincipal.abbreviation">
                          <cleave
                            id="mtdepense"
                            v-model="itemVehiculeStock.mtdepense"
                            class="form-control"
                            :raw="false"
                            placeholder="0"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group class="col" v-if="itemInStock">
                      <label for="Date arrivée">Date d'arrivée</label>
                      <validation-provider
                        #default="{ errors }"
                        name="date arrivee"
                        :rules="inStock(itemVehiculeStock) ? 'required' : ''"
                      >
                      <b-form-input
                      v-model="itemVehiculeStock.date_arrivee"
                      :date-format-options="dateFormatOptions"
                      type="date" :max="maxDate"
                      class="d-inline-block"
                      @input="checkVenteAnnulee()"
                      />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>

                  <b-row>
                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="montant vente"
                        :rules="
                          itemVehiculeStock.rap ||
                          itemVehiculeStock.vente_annulee == 'OUI'
                            ? 'required'
                            : ''
                        "
                      >
                        <label for="montant_vente">Montant vente</label>
                        <b-input-group :append="devisePrincipal.abbreviation">
                          <cleave
                            id="montant_vente"
                            v-model="itemVehiculeStock.montant_vente"
                            class="form-control"
                            :raw="false"
                            placeholder="0"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="rap"
                        :rules="
                          itemVehiculeStock.vente_annulee == 'OUI'
                            ? 'required'
                            : ''
                        "
                      >
                        <label for="rap">Reste à payer</label>
                        <b-input-group :append="devisePrincipal.abbreviation">
                          <cleave
                            id="rap"
                            v-model="itemVehiculeStock.rap"
                            class="form-control"
                            :raw="false"
                            placeholder="0"
                            :options="numberFormat"
                            :state="errors.length > 0 ? false : null"
                            @input="checkVenteAnnulee()"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="client"
                        :rules="
                          itemVehiculeStock.rap ||
                          itemVehiculeStock.vente_annulee == 'OUI'
                            ? 'required'
                            : ''
                        "
                      >
                        <label for="client">Client</label>
                        <model-list-select
                          :list="clientsList"
                          id="client"
                          v-model="itemVehiculeStock.client"
                          option-value="id"
                          option-text="name"
                          :state="errors.length > 0 ? false : null"
                        />
                        <!-- <b-form-input
                          list="clientsss"
                          id="clientsss"
                          v-model="itemVehiculeStock.client"
                          :state="errors.length > 0 ? false : null"
                        />
                         <datalist id="clientsss">
                          <option v-for="client in clientsList" :value="client.id" :text="client.name" :key="client.id">{{ client.name }}</option>
                        </datalist> -->
                        <!-- <b-form-datalist id="client" :options="clientsList">
                        </b-form-datalist> -->
                        <!-- <small class="text-danger">{{
                          errors[0] ? errors[0] : ""
                        }}</small> -->
                        <small class="text-danger">{{
                          errors[0] ? errors[0] : errorsClient
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="vente annulée"
                        :rules="itemVehiculeStock.rap ? 'required' : ''"
                      >
                        <label for="vente_annulee">Vente annulée</label>
                        <b-form-select
                          id="vente_annulee"
                          v-model="itemVehiculeStock.vente_annulee"
                          :options="vente_annulee"
                          :state="errors.length > 0 ? false : null"
                          @change="checkVenteAnnulee()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                  <b-row class="offset-10 col-2">
                    <b-form-group>
                      <label style="font-weight: 600" for="mtdepense">VALEUR DU STOCK</label>
                      <br>
                      <b-button
                          href="#"
                          variant="primary"
                          disabled
                          type="submit">
                        {{$thousandSeparator(valeurStock)}}
                      </b-button>
                  </b-form-group>
                  </b-row>
                  <div class="mt-2">
                    <b-button
                      href="#"
                      variant="primary"
                      type="submit"
                      @click="setVehiculeStockIntableList"
                    >
                      Ajouter le véhicule
                    </b-button>
                  </div>

                  <b-row class="mt-4">
                    <!-- table véhicule stock start -->
                    <b-table
                      id="my-table"
                      responsive
                      :fields="headers"
                      :items="vehiculeStockList"
                      :per-page="perPage"
                      :current-page="currentPage"
                      show-empty
                      class="border-bottom"
                    >
                      <!-- iconAction start -->
                      <template #cell(prix_achat)="data">
                        {{ $thousandSeparator(data.item.prix_achat) }}
                        <span>
                          {{ data.item.deviseA}}
                        </span>
                      </template>

                      <template #cell(prix_carte_imp)="data">
                        {{ $thousandSeparator(data.item.prix_carte_imp) }}
                        <span>
                          {{ deviseCarte.abbreviation }}
                        </span>
                      </template>

                      <template #cell(mtdepense)="data">
                        {{ $thousandSeparator(data.item.mtdepense) }}
                        <span>
                          {{ devisePrincipal.abbreviation }}
                        </span>
                      </template>

                      <template #cell(prix_transp)="data">
                        {{ $thousandSeparator(data.item.prix_transp) }}
                        <span>
                          {{ data.item.deviseT}}
                        </span>
                      </template>

                      <template #cell(frais)="data">
                        {{ $thousandSeparator(data.item.frais) }}
                        <span>
                          {{ deviseFrais.abbreviation }}
                        </span>
                      </template>

                      <template #cell(montant_vente)="data">
                        {{ $thousandSeparator(data.item.montant_vente) }}
                        <span>
                          {{ devisePrincipal.abbreviation }}
                        </span>
                      </template>

                      <template #cell(rap)="data">
                        {{ $thousandSeparator(data.item.rap) }}
                        <span>
                          {{ devisePrincipal.abbreviation }}
                        </span>
                      </template>

                      <template #cell(prix_revient)="data">
                        {{ $thousandSeparator(data.item.prix_revient) }}
                        <span>
                          {{ devisePrincipal.abbreviation }}
                        </span>
                      </template>

                      <template #cell(vente_annulee)="data">
                        {{
                          data.item.vente_annulee
                            ? data.item.vente_annulee
                            : "------"
                        }}
                      </template>
                      <template #cell(date_arrivee)="data">
                        {{ $formatDate(data.item.date_arrivee) }}
                      </template>

                      <template #cell(client)="data">
                        {{
                          data.item.client
                            ? clientsList.find(
                                (_cli) => _cli.id == data.item.client
                              ).name
                            : ""
                        }}
                      </template>

                      <template #cell(actions)="data">
                        <div class="row justify-content-center">
                          <div>
                            <b-button
                              :id="'button-1' + data.item.id"
                              size="sm"
                              variant="flat-primary"
                              class="btn-icon"
                              @click="editVehiculeStockIntableList(data.index)"
                            >
                              <feather-icon icon="Edit2Icon" />
                            </b-button>
                            <!-- <b-tooltip
                              :target="'button-1' + data.item.id"
                              title="Editer"
                              variant="dark"
                            ></b-tooltip> -->
                          </div>
                          <div>
                            <b-button
                              :id="'button-2' + data.item.id"
                              size="sm"
                              variant="flat-danger"
                              class="btn-icon"
                              @click="
                                deleteVehiculeStockIntableList(data.index)
                              "
                            >
                              <feather-icon icon="TrashIcon" />
                            </b-button>
                            <!-- <b-tooltip
                              :target="'button-2' + data.item.id"
                              title="Supprimer"
                              variant="dark"
                            ></b-tooltip> -->
                          </div>
                        </div>
                      </template>
                      <!-- iconAction end -->

                      <template #empty>
                        <div class="text-center">
                          <span class="text-nowrap"
                            >Aucune donnée existante</span
                          >
                        </div>
                      </template>
                    </b-table>
                    <div class="my-2 mx-2 row col-12">
                      <!-- per-page end -->
                      <div class="col">
                        <span class="text-nowrap mr-1"> Montré de 1 à </span>
                        <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          style="width: 70px"
                        ></b-form-select>
                        <span class="text-nowrap ml-1"> de {{ totalRows }} entrées </span>
                      </div>
                      <!-- per-page end -->

                      <!-- pagination start -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        aria-controls="my-table"
                        class="mr-1"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                      <!-- pagination end -->
                    </div>
                    <!-- table véhicule stock end -->
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- Véhicule en stock tab end -->

              <!-- Répartition du capital tab start -->
              <tab-content
                title="Répartition du capital"
                :before-change="validationFormRepartitionCapital"
              >
                <validation-observer ref="repartitionCapitalRules" tag="form">
                  <b-row>
                    <b-form-group class="ml-2 mb-1" disabled>
                      <validation-provider
                        #default="{ errors }"
                        name="capital total"
                        rules="required"
                      >
                        <label for="capital_total font-weight-bold"
                          >Capital total</label
                        >
                        <cleave
                          id="capital_total"
                          v-model="totalCapitaux"
                          class="form-control"
                          :raw="false"
                          placeholder="0"
                          :options="numberFormat"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                  <b-form-group class="hidden">
                    <validation-provider
                      #default="{ errors }"
                      name="capital total"
                      rules="required"
                    >
                      <b-form-input
                        id="verifTotal"
                        v-model="verifTotal"
                        placeholder="Total"
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>
                  <b-alert class="mt-2" :show="!verifTotal" variant="danger">
                    <div class="alert-body d-flex flex justify-space-between">
                      <span class="mr-auto"
                        >Le total des montants des associés doit être égale au
                        capital total
                      </span>
                    </div>
                  </b-alert>
                  
                  <small class="text-danger">{{
                          errorPourcentage
                    }}</small> 

                    <br><br>
                  
                    <div 
                  v-for="(item, index) in errorPourcentageRepartition"
                        :key="index">

                      <small class="text-danger">{{
                          errorPourcentageRepartition[index]
                  }}</small>
                  <br><br>
                    </div>
                  
                  
                  <b-row v-for="(item, index) in itemsAssocies" :key="index">
                    <b-form-group class="col-6" disabled>
                      <validation-provider
                        #default="{ errors }"
                        name="capital associes"
                      >
                        <label for="capital_associes1">Associé</label>
                        <b-form-input
                          id="libelle_compte_associes"
                          v-model="item.nom_associe"
                          placeholder="Libellé du compte"
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-3">
                      <validation-provider
                        #default="{ errors }"
                        name="Pourcentage"
                        rules="required"
                      >
                        <label for="montant_associes">Pourcentage</label>
                        <cleave
                          v-model="item.pourcentage_capital"
                          class="form-control"
                          @input = "checkPourcentage(index)"
                          placeholder="0"
                          :options="pourcentageformat"
                          :disabled="itemsAssocies[index].disabledPourcentage"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-3">
                      <validation-provider
                        #default="{ errors }"
                        name="montant associes"
                        rules="required"
                      >
                        <label for="montant_associes">Montant</label>
                        <cleave
                          id="montant_associes"
                          v-model="item.capital_total"
                          class="form-control"
                          :raw="false"
                          placeholder="0"
                          :disabled="disabledMontant"
                          :options="numberFormat"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- Répartition du capital tab end -->

              <!-- Syntèse tab start -->
              <tab-content
                title="Synthèse"
                :before-change="validationFormSynthèse"
              >
                <validation-observer ref="syntheseRules" tag="form">
                  <b-table-simple hover medium caption-top responsive>
                    <b-thead head-variant="dark">
                      <b-tr class="text-center">
                        <b-th colspan="2">Synthèse</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-th>CAISSE</b-th>
                        <b-td>{{
                          associationForm.etape2.montant
                            ? $thousandSeparator(associationForm.etape2.montant)
                            : 0
                        }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th>COMPTE</b-th>
                        <b-td>{{ $thousandSeparator(sommeComptes) }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th>VALEUR DU STOCK</b-th>
                        <b-td>{{ $thousandSeparator(valeurStock) }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th>MONTANT À RECOUVRER</b-th>
                        <b-td>{{ $thousandSeparator(montantRecouvrer) }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <hr />
                  <b-row>
                    <b-col
                      ><b-table-simple hover medium caption-top responsive>
                        <b-thead head-variant="dark">
                          <b-tr>
                            <b-th>DETTES</b-th>
                            <b-th
                              >TOTAL :
                              {{
                                $thousandSeparator(
                                  detteTotal + montantDedomager
                                )
                              }}</b-th
                            >
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-th>FRAIS</b-th>
                            <b-td>{{
                              associationForm.etape3.frais
                                ? $thousandSeparator(
                                    associationForm.etape3.frais *
                                      deviseFrais.value
                                  )
                                : 0
                            }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>CHARGEURS</b-th>
                            <b-td>
                              {{ $thousandSeparator(sommeMontantChargeurs) }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>CARTE IMPORTATEUR</b-th>
                            <b-td>{{
                              associationForm.etape3.ci
                                ? $thousandSeparator(
                                    associationForm.etape3.ci *
                                      deviseCarte.value
                                  )
                                : 0
                            }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>DEDOMAGEMENTS</b-th>
                            <b-td>{{
                              $thousandSeparator(montantDedomager)
                            }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple></b-col
                    >
                    <b-col
                      ><b-table-simple hover medium caption-top responsive>
                        <b-thead head-variant="dark">
                          <b-tr>
                            <b-th>CAPITAL</b-th>
                            <b-th
                              >TOTAL :
                              {{ $thousandSeparator(totalCapitaux) }}</b-th
                            >
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr
                            v-for="(item, index) in itemsAssocies"
                            :key="index"
                          >
                            <b-th>{{ item.nom_associe }}</b-th>
                            <b-td>{{
                              $thousandSeparator(item.capital_total)
                            }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple></b-col
                    >
                  </b-row>
                </validation-observer>
              </tab-content>
              <!-- Syntèse tab end -->
            </form-wizard>
          </b-form>
        </validation-observer>
        <!-- form end -->

        <!-- modal edit vehicule stock start -->
        <b-modal
          id="modal-edit"
          size="lg"
          v-model="modalEditFormVehiculeStock"
          title="Modifier les informations"
          cancel-variant="outline-secondary"
          ok-title="Modifier"
          cancel-title="Annuler"
          ok-variant="primary"
          @hidden="modalEditFormVehiculeStock = false"
          @ok="editVehicule"
          centered
        >
          <!-- form start -->
          <validation-observer ref="editForm">
            <b-form @submit.stop.prevent="editVehicule">
              <b-row>
                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="marque"
                    rules="required"
                  >
                    <label for="marque">Marque</label>
                    <b-form-input
                      list="marques"
                      id="marque"
                      v-model="editedFormVehiculeStock.marque"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-datalist id="marques" :options="marquesList">
                    </b-form-datalist>
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="numero chassis"
                    rules="required"
                  >
                    <label for="numero_chassis">Chassis</label>
                    <b-form-input
                      id="numero_chassis"
                      type="text"
                      placeholder="Chassis"
                      v-model="editedFormVehiculeStock.numero_chassis"
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <small class="text-danger">{{
                      errors[0]
                        ? errors[0]
                        : errorNumero_chassis
                        ? errorNumero_chassis
                        : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="couleur"
                    rules="required"
                  >
                    <label for="couleur">Couleur</label>
                    <combo-box-component
                      id="marque"
                      :dataSource="couleursList"
                      v-model="editedFormVehiculeStock.couleur"
                      :state="errors.length > 0 ? false : null"
                  >
                  </combo-box-component>
                  <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="annee"
                    rules="required"
                  >
                    <label for="annee">Année</label>
                    <b-form-input
                      id="annee"
                      type="number"
                      placeholder="Année"
                      v-model="editedFormVehiculeStock.annee"
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="boite"
                    rules="required"
                  >
                    <label for="boite">Boîte</label>
                    <b-form-select
                      id="boite"
                      v-model="editedFormVehiculeStock.boite"
                      :options="boite_vitesses"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="prix achat"
                    rules="required"
                  >
                    <label for="prix_achat">Achat</label>
                    <cleave
                      id="prix_achat"
                      v-model="editedFormVehiculeStock.prix_achat"
                      class="form-control"
                      :raw="false"
                      placeholder="0"
                      :options="numberFormat"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-row>

              <b-row>
                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="prix transp"
                    rules="required"
                  >
                    <label for="prix_transp">Transport</label>
                    <cleave
                      id="prix_transp"
                      v-model="editedFormVehiculeStock.prix_transp"
                      class="form-control"
                      :raw="false"
                      placeholder="0"
                      :options="numberFormat"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="prix carte importateur"
                    rules="required"
                  >
                    <label for="prix_carte_imp">Carte importateur</label>
                    <cleave
                      id="prix_carte_imp"
                      v-model="editedFormVehiculeStock.prix_carte_imp"
                      class="form-control"
                      :raw="false"
                      placeholder="0"
                      :options="numberFormat"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="frais"
                    rules="required"
                  >
                    <label for="frais">Frais</label>
                    <cleave
                      id="frais"
                      v-model="editedFormVehiculeStock.frais"
                      class="form-control"
                      :raw="false"
                      placeholder="0"
                      :options="numberFormat"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-4">
                  <validation-provider #default="{ errors }" name="rap">
                    <label for="rap">Dépenses</label>
                    <cleave
                      id="rap"
                      v-model="editedFormVehiculeStock.mtdepense"
                      class="form-control"
                      :raw="false"
                      placeholder="0"
                      :options="numberFormat"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  class="col-4"
                  v-if="
                    editedFormVehiculeStock.vente_annulee == 'OUI' ||
                    !editedFormVehiculeStock.rap
                  "
                >
                  <label for="Date arrivée">Date d'arrivée</label>
                  <validation-provider
                    #default="{ errors }"
                    name="date arrivee"
                    :rules="
                      editedFormVehiculeStock.vente_annulee == 'OUI' ||
                      !editedFormVehiculeStock.rap
                        ? 'required'
                        : ''
                    "
                  >
                    <b-form-datepicker
                      v-model="editedFormVehiculeStock.date_arrivee"
                      placeholder="Date d'arrivée"
                      reset-button
                      labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
                      labelNoDateSelected="Aucune date sélectionnée"
                      :date-format-options="dateFormatOptions"
                      locale="fr"
                    ></b-form-datepicker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="montant_vente"
                    :rules="editedFormVehiculeStock.rap ? 'required' : ''"
                  >
                    <label for="montant_vente">Montant de vente</label>
                    <cleave
                      id="montant_vente"
                      v-model="editedFormVehiculeStock.montant_vente"
                      class="form-control"
                      :raw="false"
                      placeholder="0"
                      :options="numberFormat"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                
              </b-row>
              <b-row>
                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="rap"
                    :rules="
                      editedFormVehiculeStock.vente_annulee == 'OUI'
                        ? 'required'
                        : ''
                    "
                  >
                    <label for="rap">Reste à payer</label>
                    <cleave
                      id="rap"
                      v-model="editedFormVehiculeStock.rap"
                      class="form-control"
                      :raw="false"
                      placeholder="0"
                      :options="numberFormat"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="client"
                    :rules="editedFormVehiculeStock.rap ? 'required' : ''"
                  >
                    <label for="client">Client</label>
                    <model-list-select
                      :list="clientsList"
                      id="client"
                      v-model="editedFormVehiculeStock.client"
                      option-value="id"
                      option-text="name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0] ? errors[0] : errorsClient
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    name="vente annulée"
                    :rules="editedFormVehiculeStock.rap ? 'required' : ''"
                  >
                    <label for="vente_annulee">Vente annulée</label>
                    <b-form-select
                      id="vente_annulee"
                      v-model="editedFormVehiculeStock.vente_annulee"
                      :options="vente_annulee"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-row>
            </b-form>
          </validation-observer>
          <!-- form end -->
        </b-modal>
        <!-- modal edit vehicule stock end -->

        <!-- submit button start -->
        <div class="col-8 mt-3">
          <b-button
            v-if="activeEtape == 0"
            class="mr-2"
            variant="outline-secondary"
            type="reset"
            @click="$router.go(-1)"
          >
            Annuler
          </b-button>

          <span v-if="activeEtape == 5">
            <b-button
              v-if="etatButton"
              variant="primary"
              type="submit"
              @click="saveAssociations"
              class="mr-2"
            >
              Terminer
            </b-button>
            <b-button v-else variant="primary" class="mr-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </span>
        </div>
        <!-- submit button end -->
      </b-card>
    </div>
    <b-modal
      size="lg"
      id="cahrgeur-modal"
      modal-class="modal-primary"
      v-model="chargeurDialog"
      title="AJOUTER UN CHARGEUR"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      ok-title="Enregistrer"
      ok-variant="primary"
      @hidden="resetChargeur"
      :ok-disabled="isOperating"
      @ok="saveChargeur"
      centered
    >
      <b-form class="mt-1" ref="printForm" @submit.stop.prevent="saveChargeur">
        <b-row class="formRow">
          <b-form-group class="col mr-2">
            <validation-provider
              #default="{ errors }"
              name="Nom et Prenom"
              rules="required"
            >
              <label for="name">Nom</label>
              <b-form-input
                id="name"
                v-model="chargeurForm.name"
                :state="errors.length > 0 ? false : null"
                type="text"
                placeholder="Nom et Prenom"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group class="col mr-2">
            <validation-provider
              #default="{ errors }"
              name="devise"
              rules="required"
            >
              <label for="phone">Dévise</label>
              <b-form-select
                id="devise"
                v-model="chargeurForm.devise"
                :options="devises"
                value-field="id"
                text-field="name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
      import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

import { mapState } from "vuex";
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
import { ModelListSelect } from "vue-search-select";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { localize, ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
// import { localize } from 'vee-validate';
// import { required } from "vee-validate/dist/rules";
import Cleave from "vue-cleave-component";
import moment from 'moment'

localize({
  en: {
    messages: {
      required: "this field is required",
      min: "this field must have no less than {length} characters",
      max: (_, { length }) =>
        `this field must have no more than ${length} characters`,
    },
  },
});

import {
  BCard,
  BCardText,
  BAlert,
  BModal,
  BButton,
  BTooltip,
  BBadge,
  BIconPlus,
  BIconPencilFill,
  BIconTrashFill,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BFormDatepicker,
  BCol,
  BTable,
  BFormGroup,
  BInputGroup,
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BFormDatalist,
  BCollapse,
  BFormInvalidFeedback,
  BIconArrowClockwise,
  BIconCircleFill,
  BTableSimple,
  BThead,
  BTfoot,
  BTh,
  BTd,
  BTr,
  BTbody,
  BInputGroupAppend,
  BInputGroupPrepend,
  BPagination
} from "bootstrap-vue";
import {
  ComboBoxComponent,
  ComboBoxPlugin,
} from "@syncfusion/ej2-vue-dropdowns";

export default {
  components: {
    BPagination,
    ComboBoxPlugin,
    ModelListSelect,
    ComboBoxComponent,
    BTableSimple,
    BThead,
    BTfoot,
    BTh,
    BTd,
    BTr,
    BTbody,
    BCard,
    BModal,
    BFormDatepicker,
    DatePicker,
    BCardText,
    BBadge,
    BTooltip,
    Cleave,
    BFormDatalist,
    BAlert,
    BCardCode,
    BButton,
    EmptyList,
    BIconPlus,
    BIconPencilFill,
    BIconTrashFill,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BTable,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormSelectOption,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BIconCircleFill,
    BIconArrowClockwise,
    FormWizard,
    TabContent,
    BInputGroupAppend,
    BInputGroupPrepend,
  },

  data() {
    return {
      etatButton: true,
      etatButtonEdit: true,
      add: false,
      empty: true,
      required,
      errorPourcentageRepartition: [],
      isOperating: false,
      integer,
      alpha,
      alphaDash,
      nameState: null,
      // totalRows: 1,
      dateFormatOptions: { day: "numeric", month: "numeric", year: "numeric" },
      perPage: 5,
      currentPage: 1,
      filter: null,
      pageOptions: [5, 10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      devisePrincipal: {},
      etatButtonVehiculeStock: true,
      startIndex: 0,
      maxDate:0,
      nextauxachat:"",
      newtauxtranspo:"",
      devise_acha:0,
      devise_trans:0,
      activeEtape: 0,
      devises: [],
      clientsList: [],
      associesList: [],
      errorPourcentage: null,
      marquesList: [],
      couleursList: [],
      associations: [],
      editedFormVehiculeStock: {},
      disabledMontant: true,
      modalEditFormVehiculeStock: false,
      vehiculeStockList: [],
      headers: [
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: "width: 120px",
        },
        {
          key: "marque",
          label: "Marque",
          sortable: true,
        },
        {
          key: "numero_chassis",
          label: "Chassis",
          sortable: true,
        },
        {
          key: "couleur",
          label: "Couleur",
          sortable: true,
        },
        {
          key: "annee",
          label: "Année",
          sortable: true,
        },
        {
          key: "boite",
          label: "Boite",
          sortable: true,
        },
        {
          key: "prix_achat",
          label: "Achat",
          sortable: true,
        },
        {
          key: "prix_transp",
          label: "Transport",
          sortable: true,
        },
        {
          key: "frais",
          label: "Frais",
          sortable: true,
        },
        {
          key: "prix_carte_imp",
          label: "Carte importateur",
          sortable: true,
        },
        {
          key: "montant_vente",
          label: "Montant vente",
          sortable: true,
        },
        {
          key: "rap",
          label: "Reste à payer",
          sortable: true,
        },
        {
          key: "mtdepense",
          label: "Dépense",
          sortable: true,
        },
        {
          key: "client",
          label: "Client",
          sortable: true,
        },
        {
          key: "vente_annulee",
          label: "Vente annulée",
          sortable: true,
        },
        {
          key: "date_arrivee",
          label: "Date d'arrivée",
          sortable: true,
        },
        {
          key: "prix_revient",
          label: "Prix de revient",
          sortable: true,
        },

      ],
      boite_vitesses: ["Manuel", "Automatique"],
      cylindres: ["V4", "V6", "V8", "V12"],
      vente_annulee: ["OUI", "NON"],
      numberFormat: {
        numeral: true,
        delimiter: " ",
        numeralThousandsGroupStyle: "thousand",
      },
      pourcentageformat: {
        numeral: true,
        numeralPositiveOnly: true
      },
      associationForm: {
        etape: 0,
        etape1: {
          nameAssociation: "",
          codeAssociation: "",
          associes: "",
          acheteurs: "",
        },
        etape2: {
          compte: [],
          montantCaisse: "",
          montant: 0,
        },
        etape3: {
          ci: "",
          frais: "",
          chargeurs: [],
        },
        etape4: {
          vehiculeStock: "",
        },
        etape5: {
          capitalTotal: "",
          capital_associes: [],
        },
      },
      itemsAssocies: [
        {
          nom_associe: "",
          errorNomAssocies: "",
        },
      ],
      comptesAssocies: [
        {
          associe: "",
          errorNomAssocies: "",
        },
      ],
      itemsAcheteurs: [
        {
          nom_acheteur: "",
          errorNomAcheteur: "",
        },
      ],
      compteAcheteurs: [
        {
          nom_acheteur: "",
          solde_initial: 0,
        },
      ],

      
      itemsChargeurs: [{}],

      itemVehiculeStock: {
        marque: "",
        devise_achat: "",
        devise_transp: "",
        newdevise_achat: 0,
        newdevise_transp: 0,
        newdevise_frais: 0,
        newdevise_import: 0,
        numero_chassis: "",
        couleur: "",
        annee: "",
        boite: "Automatique",
        cylindre: "V4",
        mtdepense: 0,
        prix_achat: 0,
        prix_revient: 0,
        prix_transp: 0,
        prix_carte_imp: 0,
        frais: 0,
        montant_vente: "",
        rap: "",
        client: "",
        vente_annulee: "NON",
      },

      nextTodoIdAssocies: 2,
      nextTodoIdAcheteur: 2,
      nextTodoIdCompteAssocies: 2,
      disabledButtonAssocies: false,
      disabledButtonAcheteur: false,
      disabledButtonCompteAssocies: false,
      showRemoveItemAssocies: 1,
      showRemoveItemAcheteur: 1,
      showRemoveItemCompteAssocies: 1,
      errorCheckNomAssocies: [],
      errorCheckNomAcheteur: [],
      marqueData: {
        name: "",
      },
      colorData: {
        name: "",
      },
      errorsNameAssociation: null,
      pourcentage_capital: 0,
      errorsCodeAssociation: null,
      errorsMontantCaisse: null,
      errorsCarteImportateur: null,
      errorsFrais: null,
      comptes: [],
      errorsClient: null,
      errorNumero_chassis: null,
      ended: false,
      itemInStock: true,
      // ci: "",
      // frais: "",
      // chargeurs: [],
      listeChargeurs: [],
      // isDisabledcolor: "",
      hiddenLoader: false,
      chargeurDialog: false,
      chargeurForm: {},
      chargeurNewIndex: null,
      devisesManif: {},
      navires: [],
      pourcentageTotal: 0,
      associes: [],
      vehicules2: [],
      associations2: [],
    };
  },
  created() {
    this.getChargeurs();
    this.getManifDevises();
    this.getClients();
    this.getMarquesList();
    this.getCouleursList();
    this.getNavires();
    this.getDevises();
    this.getComptes();
    this.getAssocies();
    this.getVehicules2();
    this.getAssociations2();
    this.removeItemAcheteur();
    this.removeItem();
  },

  computed: {
    totalRows() {
      // Set the initial number of items
      return this.vehiculeStockList.length;

    },

    totalCapitaux() {
      // Caisse + Montant dans les comptes (acheteurs et associés) + Valeur en stock + Mt à recouvrir - Dettes
      return (this.associationForm.etape5.capitalTotal =
        this.$thousandFormater(this.associationForm.etape2.montant) +
        this.sommeComptes +
        this.valeurStock +
        this.montantRecouvrer -
        this.detteTotal -
        this.montantDedomager);
    },
    detteTotal() {
      return (
        this.$thousandFormater(this.associationForm.etape3.frais) +
        this.$thousandFormater(this.associationForm.etape3.ci) +
        this.sommeMontantChargeurs
      );
    },
    sommeMontantChargeurs() {
      if (this.itemsChargeurs.length) {
        let somme = 0;
        this.itemsChargeurs.forEach((element) => {
          if (element.devise && element.chargeur > 0) {
            somme +=
              this.$thousandFormater(element.montant) * element.devise.value;
          }
        });
        return somme;
      } else {
        return 0;
      }
    },
    montantRecouvrer() {
      let montant = 0;
      if (this.vehiculeStockList.length > 0) {
        this.vehiculeStockList.forEach((vehicule) => {
          if (!this.inStock(vehicule)) {
            montant += vehicule.rap ? this.$thousandFormater(vehicule.rap) : 0;
          }
        });
      }
      return montant;
    },
    montantDedomager() {
      let montant = 0;
      if (this.vehiculeStockList.length > 0) {
        this.vehiculeStockList.forEach((vehicule) => {
          if (this.inStock(vehicule) && vehicule.vente_annulee == "OUI") {
            montant +=
              this.$thousandFormater(vehicule.montant_vente) -
              this.$thousandFormater(vehicule.rap);
          }
        });
      }
      return montant;
    },
    sommeComptes() {
      let somme = 0;
      var acheteurs = this.associationForm.etape2.compte.acheteurs;
      var associes = this.associationForm.etape2.compte.associes;
      if (acheteurs.length) {
        acheteurs.forEach((element) => {
          if (element.devise) {
            let devise = this.devises.find(
              (_dev) => _dev.id == parseInt(element.devise)
            );
            somme += element.solde_initial
              ? element.solde_initial * devise.value
              : 0;
          }
        });
      }
      if (associes.length) {
        associes.forEach((element) => {
          if (element.devise) {
            let devise = this.devises.find(
              (_dev) => _dev.id == parseInt(element.devise)
            );
            somme += element.solde_initial
              ? element.solde_initial * devise.value
              : 0;
          }
        });
      }
      return somme;
    },

    verifTotal() {
      this.makeFormat();
      let montantTotal = this.associationForm.etape5.associesCapital
        ? this.associationForm.etape5.associesCapital.reduce(function (
            sum,
            current
          ) {
            return sum + current.capital_total;
          },
          0)
        : 0;
      if (montantTotal == this.totalCapitaux) {
        return "oui";
      } else {
        return null;
      }
    },
    controlOverExistingAssociation() {
      let checkName = this.associations2.find(
        (_asso) =>
          _asso.name.toUpperCase()  == this.associationForm.etape1.nameAssociation.toUpperCase()
      );
      let checkCode = this.associations2.find(
        (_asso) =>
          _asso.code.toUpperCase() == this.associationForm.etape1.codeAssociation.toUpperCase()
      );
      if (checkName || checkCode) {
        return null;
      } else {
        return "oui";
      }
    },

    totalPrixAchat() {
      let montant = 0;
      if (this.vehiculeStockList.length > 0) {
        this.vehiculeStockList.forEach((vehicule) => {
          montant +=
            vehicule.prix_achat && this.inStock(vehicule)
              ? this.$thousandFormater(vehicule.prix_achat)
              : 0;
        });
      }
      return montant;
    },
    totalPrixTransport() {
      let montant = 0;
      if (this.vehiculeStockList.length > 0) {
        this.vehiculeStockList.forEach((vehicule) => {
          montant +=
            vehicule.prix_transp && this.inStock(vehicule)
              ? this.$thousandFormater(vehicule.prix_transp)
              : 0;
        });
      }
      return montant;
    },

    totalPrixCarteImp() {
      let montant = 0;

      if (this.vehiculeStockList.length > 0) {
        this.vehiculeStockList.forEach((vehicule) => {
          montant +=
            vehicule.prix_carte_imp && this.inStock(vehicule)
              ? this.$thousandFormater(vehicule.prix_carte_imp)
              : 0;
        });
      }
      return montant;
    },
    totalFrais() {
      let montant = 0;
      if (this.vehiculeStockList.length > 0) {
        this.vehiculeStockList.forEach((vehicule) => {
          montant +=
            vehicule.frais && this.inStock(vehicule)
              ? this.$thousandFormater(vehicule.frais)
              : 0;
        });
      }
      return montant;
    },
    totalDepense() {
      let montant = 0;
      if (this.vehiculeStockList.length > 0) {
        this.vehiculeStockList.forEach((vehicule) => {
          montant +=
            vehicule.mtdepense && this.inStock(vehicule)
              ? this.$thousandFormater(vehicule.mtdepense)
              : 0;
        });
      }
      return montant;
    },

    valeurStock() {
      let valeur = 0;
      if (this.vehiculeStockList.length > 0) {
        this.vehiculeStockList.forEach((vehicule) => {
          valeur +=
              vehicule.prix_revient && this.inStock(vehicule)
                  ? this.$thousandFormater(vehicule.prix_revient)
                  : 0;
        });
      }
      return valeur;
    },

    ...mapState({
      user: (state) => state.user.auth,
    }),
    deviseAchat() {
      return this.devisesManif.devise_achat
        ? this.devisesManif.devise_achat
        : { value: 0 };
    },
    deviseTransport() {
      return this.devisesManif.devise_transport
        ? this.devisesManif.devise_transport
        : { value: 0 };
    },
    deviseCarte() {
      return this.devisesManif.devise_carte_imp
        ? this.devisesManif.devise_carte_imp
        : { value: 0 };
    },
    deviseFrais() {
      return this.devisesManif.devise_frais
        ? this.devisesManif.devise_frais
        : { value: 0 };
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.ended) {
      next();
    } else {
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous réelement quitter cette page ? Tous vos enregistrements seront perdus",
          {
            title: "Quitter cette page",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            next();
          } else {
            next(false);
          }
        });
    }
  },

  methods: {
    checkVenteAnnulee() {
      this.inStock(this.itemVehiculeStock);
    },

    inStock(vehicule) {
      if (vehicule.vente_annulee == "OUI") {
        this.itemInStock = true;
        return true;
      } else {
        if (!vehicule.rap) {
          this.itemInStock = true;
          return true;
        }
        this.itemInStock = false;
        return false;
      }
      // if(this.$thousandFormater(vehicule.rap) == 0 && !vehicule.client){
      //   return true
      // }else if(this.$thousandFormater(vehicule.rap) && vehicule.vente_annulee == "OUI"){
      //   return true
      // }else{
      //   return false
      // }
    },

    prixRevientItem(item) {
      if (this.newdevise_achat && this.newdevise_achat !==0 && this.newdevise_transp && this.newdevise_transp !==0 ){
        if(this.devisesManif.dev_achat){
          return (item.prix_revient =
              this.$thousandFormater(item.prix_achat) * this.newdevise_achat +
              this.$thousandFormater(item.prix_carte_imp) * this.deviseCarte.value +
              this.$thousandFormater(item.prix_transp) * this.newdevise_transp +
              this.$thousandFormater(item.frais) * this.deviseFrais.value);
        }
      }else{
        if(this.devisesManif.dev_achat){
          return (item.prix_revient =
              this.$thousandFormater(item.prix_achat) * this.deviseAchat.value +
              this.$thousandFormater(item.prix_carte_imp) * this.deviseCarte.value +
              this.$thousandFormater(item.prix_transp) * this.deviseTransport.value +
              this.$thousandFormater(item.frais) * this.deviseFrais.value);
        }
      }

    },
    getComptes() {
      this.$http.get("/comptes").then((response) => {
        this.comptes = response.data.data;
        this.comptes.forEach((element) => {
          element.text = element.libelle;
        });
      });
    },
    getAssocies() {
      this.$http.get("/associes").then((response) => {
        this.associes = response.data.data;
        this.associes.forEach((element) => {
          element.text = element.name;
        });
      });
    },
    getVehicules2() {
      this.$http.get("/vehicules2").then((response) => {
        this.vehicules2 = response.data.data;
      });
    },
    getAssociations2() {
      this.$http.get("/associations2").then((response) => {
        this.associations2 = response.data.data;
      });
    },
    getManifDevises() {
      this.$http.get("/devises-manifeste").then((response) => {
        this.devisesManif = response.data.data[0];
        this.devisesManif.dev_achat_transport = this.devisesManif.dev_achat;
      });
    },
    checkDeviseAchat() {

      this.devisesManif.devise_achat = this.devises.find(
        (_dev) => _dev.id == this.devisesManif.dev_achat
      );
  /*    this.vehiculeStockList.forEach((element) => {
        this.prixRevientItem(element);
      });*/
      this.newdevise_achat=this.devisesManif.devise_achat.value
    },

    checkDeviseTransport() {
      this.devisesManif.devise_transport = this.devises.find(
        (_dev) => _dev.id == this.devisesManif.dev_transport
      );
      this.newdevise_transp=this.devisesManif.devise_transport.value
/*
      this.vehiculeStockList.forEach((element) => {
        this.prixRevientItem(element);
      });*/
    },

    addChargeur(index) {
      this.chargeurNewIndex = index;
      this.chargeurDialog = true;
    },
    getDeviseChargeur(item) {
      if (item.chargeur) {
        let chargeur = this.listeChargeurs.find(
          (_char) => _char.id == item.chargeur
        );
        if (chargeur) {
          let deviseText = this.devises.find(
            (_dev) => _dev.id == parseInt(chargeur.devise)
          );
          item.devise = deviseText;
          return deviseText.name;
        }
      }
      return "";
    },

    resetChargeur() {
      this.chargeurForm = {};
      this.items[this.chargeurNewIndex].chargeur = "";
      this.chargeurDialog = false;
    },

    saveChargeur(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;
      this.$http
        .post("/chargeurs", this.chargeurForm)
        .then((result) => {
          this.isOperating = false;
          if (result.data.success) {
            this.showToast("Chargeur enregistré avec succès", "success");

            this.$http.get("/chargeurs").then((res) => {
              this.chargeurs = res.data.data;
              this.listeChargeurs = res.data.data;
              this.chargeurDialog = false;
            });
          } else {
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.chargeurDialog = false;
          this.showToast("Cet élement ne peut être enregistré", "danger");
        });
    },

    formatAmounts(dettes) {
      dettes = JSON.parse(JSON.stringify(dettes));

      dettes.forEach((element) => {
        if (element.ci) {
          element.ci = this.$thousandFormater(element.ci);
        }

        if (element.montant) {
          element.montant = this.$thousandFormater(element.montant);
        }

        if (element.prix_revient) {
          element.prix_revient = this.$thousandFormater(element.prix_revient);
        }
        if (element.frais) {
          element.frais = this.$thousandFormater(element.frais);
        }
        if (element.montant_vente) {
          element.montant_vente = this.$thousandFormater(element.montant_vente);
        }
        if (element.prix_achat) {
          element.prix_achat = this.$thousandFormater(element.prix_achat);
        }
        if (element.prix_carte_imp) {
          element.prix_carte_imp = this.$thousandFormater(
            element.prix_carte_imp
          );
        }
        if (element.prix_transp) {
          element.prix_transp = this.$thousandFormater(element.prix_transp);
        }
        if (element.rap) {
          element.rap = this.$thousandFormater(element.rap);
        }
        if (element.mtdepense) {
          element.mtdepense = this.$thousandFormater(element.mtdepense);
        }

        if (element.solde_initial) {
          element.solde_initial = this.$thousandFormater(element.solde_initial);
        }
        if (element.capital_total) {
          element.capital_total = this.$thousandFormater(element.capital_total);
        }
      });
      return dettes;
    },

    formatAmount(amount) {
      amount = this.$thousandFormater(amount);
      return amount;
    },

    checkFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    getChargeurs() {
      this.$http.get("/chargeurs").then((res) => {
        if (res.status) {
          this.hiddenLoader = true;
        }

        this.chargeurs = res.data.data;
        this.listeChargeurs = res.data.data;
      });
    },


    checkPourcentage(index) {
      this.pourcentageTotal = 0
      this.errorPourcentageRepartition= []
      for (let i = 0; i < this.itemsAssocies.length; i++) {
        if(this.itemsAssocies[i].pourcentage_capital) {
          this.pourcentageTotal += parseInt(this.itemsAssocies[i].pourcentage_capital);
        }
      }

      for (let i = 0; i < this.itemsAssocies.length; i++) {
        if(!this.itemsAssocies[i].pourcentage_capital && this.pourcentageTotal) {
          this.errorPourcentageRepartition.push("L'associé "+this.itemsAssocies[i].nom_associe+" n'a pas reçu un pourcentage du capital")
        } else {
          this.errorPourcentageRepartition = []
        }
      }
      
            
      this.itemsAssocies[index].capital_total = (this.itemsAssocies[index].pourcentage_capital * this.totalCapitaux)/100;
        
      if(this.pourcentageTotal > 100) {
        this.errorPourcentage = "Le pourcentage dépasse 100%"
      } else if(this.pourcentageTotal < 100) {
        this.errorPourcentage = "Le pourcentage total doit être égal à 100%"
      } else if(this.pourcentageTotal == 100) {
        this.errorPourcentage =''
      }
    },

    refreshChargeur(item, index) {
      if (item == "NOUVEAU_CHARGEUR") {
        this.addChargeur(index);
      } else {
        const check = this.listeChargeurs.find(({ id }) => id === item);
        if (check) {
          check.isDisabled = true;
        }
      }
    },


    repeateAgain() {
      this.itemsChargeurs.push({
        id: (this.nextTodoId += this.nextTodoId),
      });
    },


    removeItem(index) {
      const check = this.listeChargeurs.find(
        ({ id }) => id === this.itemsChargeurs[index].chargeur
      );
      if (check) {
        check.isDisabled = false;
      }
      this.itemsChargeurs.splice(index, 1);
    },

    activeTabIndex(data) {
      this.activeEtape = data;

      if (data == 1) {
        this.associesList = [];
        this.itemsAssocies.forEach((element, index) => {
          var obj = {};

          obj.id = index;
          obj.name = element.nom_associe;
          this.associesList.push(obj);
        });

        for (let i = 0; i < this.itemsAssocies.length; i++) {
          if (this.itemsAssocies[i].errorNomAssocies) {
            checkErrorAssocies = true;
            i = this.itemsAssocies.length;
          }
        }

        for (let i = 0; i < this.itemsAcheteurs.length; i++) {
          if (this.itemsAcheteurs[i].errorNomAcheteur) {
            checkErrorAcheteur = true;
            i = this.itemsAcheteurs.length;
          }
        }
        let checkErrorAssocies = false;
        let checkErrorAcheteur = false;
        if (
          !checkErrorAssocies &&
          !checkErrorAcheteur &&
          !this.errorsNameAssociation &&
          !this.errorsCodeAssociation
        ) {
          this.associationForm.etape1.nameAssociation =
            this.associationForm.etape1.nameAssociation.toUpperCase();
          this.associationForm.etape1.codeAssociation =
            this.associationForm.etape1.codeAssociation.toUpperCase();

          for (let i = 0; i < this.itemsAssocies.length; i++) {
            if (this.itemsAssocies[i].nom_associe) {
              this.itemsAssocies[i].nom_associe =
                this.itemsAssocies[i].nom_associe.toUpperCase();
            }
          }

          for (let i = 0; i < this.itemsAcheteurs.length; i++) {
            if (this.itemsAcheteurs[i].nom_acheteur) {
              this.itemsAcheteurs[i].nom_acheteur =
                this.itemsAcheteurs[i].nom_acheteur.toUpperCase();
            }
          }

          this.associationForm.etape1.associes = this.itemsAssocies;
          this.associationForm.etape1.acheteurs = this.itemsAcheteurs;
        }
      }

      if (data == 2) {
        this.associationForm.etape2.compte = {
          acheteurs: this.compteAcheteurs,
          associes: this.comptesAssocies,
        };
        // this.associationForm.etape2.compte.push(this.compteAcheteurs);
        // this.associationForm.etape2.compte.push(this.comptesAssocies);
        if(this.itemsAssocies.length <= 1) {
            this.itemsAssocies[0].pourcentage_capital = 100
            this.itemsAssocies[0].capital_total = this.totalCapitaux
            this.itemsAssocies[0].disabledPourcentage = true
          }
          else {
            this.itemsAssocies[0].disabledPourcentage = false
           
          }
       
      }

      if (data == 3) {
        this.associationForm.etape3.chargeurs = this.itemsChargeurs;
        
      }

      if (data == 4) {
        this.associationForm.etape4.vehiculeStock = this.vehiculeStockList;
        if(this.itemsAssocies.length <= 1) {
            this.itemsAssocies[0].pourcentage_capital = 100
            this.itemsAssocies[0].capital_total = this.totalCapitaux
            this.itemsAssocies[0].disabledPourcentage = true
          }
          else {
            this.itemsAssocies[0].disabledPourcentage = false
           
          }
       
      }

      if (data == 5) {
        
        var object1 = this.associationForm.etape2.compte.acheteurs;
        var object2 = this.associationForm.etape2.compte.associes;
        var object3 = this.associationForm.etape4.vehiculeStock;
        var object4 = this.associationForm.etape3.chargeurs;

        var val1 = 0;
        var val2 = 0;
        var val3 = 0;
        var val4 = 0;
        // somme des sold_initial des comptes
        object1.forEach((element) => {
          val1 =
            parseInt(val1) +
            parseInt(this.$thousandFormater(element.solde_initial));
        });

        object2.forEach((element) => {
          val2 =
            parseInt(val2) +
            parseInt(this.$thousandFormater(element.solde_initial));
        });

        // this.sommeComptes = this.$thousandSeparator(
        //   parseInt(val1) + parseInt(val2)
        // );

        // Montant recouvrer
        object3.forEach((element) => {
          val3 = parseInt(val3) + parseInt(this.$thousandFormater(element.rap));
        });

        // this.montantRecouvrer = this.$thousandSeparator(val3);

        // Montant chargeur dette
        object4.forEach((element) => {
          val4 =
            parseInt(val4) + parseInt(this.$thousandFormater(element.montant));
        });
      }
    },

    etatAdd() {
      this.add = true;
      this.empty = false;
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    deleteCreateAssociation() {
      this.$router.go(-1);
    },

    makeFormat() {
      //STEP 2

      this.associationForm.etape2.compte = {
        acheteurs: this.compteAcheteurs,
        associes: this.comptesAssocies,
      };
      let acheteurs = this.formatAmounts(this.compteAcheteurs);
      let associes = this.formatAmounts(this.comptesAssocies);

      this.associationForm.etape2.compte.acheteurs = acheteurs;
      this.associationForm.etape2.compte.associes = associes;
      this.associationForm.etape2.montantCaisse = this.$thousandFormater(
        this.associationForm.etape2.montant
      );
      //STEP 3
      this.associationForm.etape3.chargeurs = this.itemsChargeurs;

      this.associationForm.etape3.ci = this.$thousandFormater(
        this.associationForm.etape3.ci
      );
      this.associationForm.etape3.frais = this.$thousandFormater(
        this.associationForm.etape3.frais
      );
      if (this.associationForm.etape3.chargeurs.length) {
        let amounts = this.formatAmounts(this.associationForm.etape3.chargeurs);
        this.associationForm.etape3.chargeurs = amounts;
      }

      //STEP 4
      let vehicules = this.formatAmounts(this.vehiculeStockList);
      this.associationForm.etape4.vehiculeStock = vehicules;
      this.associationForm.etape4.devisesManifeste = this.devisesManif;
      if (this.vehiculeStockList.length) {
        this.associationForm.etape4.prix_revient =
          this.associationForm.etape4.vehiculeStock.reduce(function (
            sum,
            current
          ) {
            return sum + (current ? current.prix_revient : 0);
          },
          0);
      }

      //STEP 5
      this.associationForm.etape5.capitalTotal = this.formatAmount(
        this.associationForm.etape5.capitalTotal
      );
      let capitaux = this.formatAmounts(this.itemsAssocies);
      this.associationForm.etape5.associesCapital = capitaux;
    },

    saveCouleur() {
      var couleurSelect = this.vehiculeStockList[0].couleur

      let colorExist = this.couleursList.find(
          ({ name }) => name === couleurSelect
        );
        this.colorData.name = couleurSelect
        
        if(!colorExist) {
          this.$http
                    .post("/couleurs", this.colorData)
                    .then((result) => {
                      if (result.data.success) {
                        this.getCouleursList()
                      } else {
                        console.log("Echec d'enregistrement de la couleur")
                      }
                    })
                    .catch((e) => {
                      
                      console.log(e)
                    });
        }
           
    },

    saveMarque() {
      
      var marqueSelect = this.vehiculeStockList[0].marque
      let marqueExist = this.marquesList.find(
          ({ name }) => name === marqueSelect
        );
        this.marqueData.name = marqueSelect
        if(!marqueExist) {

                  this.$http
                    .post("/marques", this.marqueData)
                    .then((result) => {
                      if (result.data.success) {
                        this.getMarquesList()
                      } else {
                        console.log("Echec d'enregistrement de la marque")
                      }
                    })
                    .catch((e) => {
                      
                    });

                  }
    },

    saveAssociations() {
      switch (this.activeEtape) {
        case 0:
          this.$refs.infoGeneraleRules.validate().then((success) => {
            if (success) {
              if (
                !this.errorCheckNomAssocies.length &&
                !this.errorCheckNomAcheteur.length
              ) {
                let checkErrorAssocies = false;
                let checkErrorAcheteur = false;

                if (this.itemsAssocies.length == 0) {
                  this.showToast(
                    "Veuillez ajouter au moins un associés à l'association",
                    "danger"
                  );
                }

                if (this.itemsAcheteurs.length == 0) {
                  this.showToast(
                    "Veuillez ajouter au moins un acheteur à l'association",
                    "danger"
                  );
                }

                for (let i = 0; i < this.itemsAssocies.length; i++) {
                  if (this.itemsAssocies[i].errorNomAssocies) {
                    checkErrorAssocies = true;
                    i = this.itemsAssocies.length;
                  }
                }

                for (let i = 0; i < this.itemsAcheteurs.length; i++) {
                  if (this.itemsAcheteurs[i].errorNomAcheteur) {
                    checkErrorAcheteur = true;
                    i = this.itemsAcheteurs.length;
                  }
                }

                if (
                  !checkErrorAssocies &&
                  !checkErrorAcheteur &&
                  !this.errorsNameAssociation &&
                  !this.errorsCodeAssociation
                ) {
                  this.associationForm.etape1.nameAssociation =
                    this.associationForm.etape1.nameAssociation.toUpperCase();
                  this.associationForm.etape1.codeAssociation =
                    this.associationForm.etape1.codeAssociation.toUpperCase();

                  for (let i = 0; i < this.itemsAssocies.length; i++) {
                    if (this.itemsAssocies[i].nom_associe) {
                      this.itemsAssocies[i].nom_associe =
                        this.itemsAssocies[i].nom_associe.toUpperCase();
                    }
                  }

                  for (let i = 0; i < this.itemsAcheteurs.length; i++) {
                    if (this.itemsAcheteurs[i].nom_acheteur) {
                      this.itemsAcheteurs[i].nom_acheteur =
                        this.itemsAcheteurs[i].nom_acheteur.toUpperCase();
                    }
                  }

                  this.associationForm.etape1.associes = this.itemsAssocies;
                  this.associationForm.etape1.acheteurs = this.itemsAcheteurs;

                  this.associationForm.etape = 1;

                  this.etatButton = false;

                  this.$http
                    .post("/associations", this.associationForm)
                    .then((result) => {
                      if (result.data.success) {
                        this.etatButton = true;
                        this.showToast("Enregistrement effectué", "success");
                        this.$router.go(-1);
                      } else {
                        this.etatButton = true;
                        this.showToast(result.data.message, "danger");
                      }
                    })
                    .catch((e) => {
                      this.etatButton = true;

                      var err = e.response.data.errors;

                      if (err.nameAssociation) {
                        this.errorsNameAssociation = err.nameAssociation[0];
                      }

                      if (err.codeAssociation) {
                        this.errorsCodeAssociation = err.codeAssociation[0];
                      }
                    });
                }
              }
            }
          });
          break;
        case 1:
          // this.$refs.compteRules.validate().then((success) => {
          // if (success) {
          this.makeFormat();
          this.associationForm.etape = 2;
          this.etatButton = false;
          this.$http
            .post("/associations", this.associationForm)
            .then((result) => {
              if (result.data.success) {
                this.etatButton = true;
                this.showToast("Enregisrement effectué", "success");
                this.$router.go(-1);
              } else {
                this.etatButton = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButton = true;
              var err = e.response.data.errors;
            });
          // }
          // });
          break;
        case 2:
          // this.$refs.detteRules.validate().then((success) => {
          //   if (success) {
          this.makeFormat();

          this.associationForm.etape = 3;

          this.etatButton = false;

          this.$http
            .post("/associations", this.associationForm)
            .then((result) => {
              if (result.data.success) {
                this.etatButton = true;
                this.showToast("Enregisrement effectué", "success");
                this.$router.go(-1);
              } else {
                this.etatButton = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButton = true;

              var err = e.response.data.errors;
            });
          //   }
          // });
          break;
        case 3:
          // this.$refs.vehiculeStockRules.validate().then((success) => {
          //   if (success) {
          this.makeFormat();
          // this.associationForm.etape3.chargeurs = this.itemsChargeurs;

          this.associationForm.etape = 4;

          this.etatButton = false;
          this.$http
            .post("/associations", this.associationForm)
            .then((result) => {
              if (result.data.success) {
                this.etatButton = true;
                this.showToast("Enregisrement effectué", "success");
                this.$router.go(-1);
              } else {
                this.etatButton = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButton = true;

              var err = e.response.data.errors;
            });
          //   }
          // });
          break;
        case 4:
          // this.$refs.repartitionCapitalRules.validate().then((success) => {
          //   if (success) {
          this.makeFormat();
          let montantTotal = this.associationForm.etape5.associesCapital.reduce(
            function (sum, current) {
              return sum + current.capital_total;
            },
            0
          );

          if (montantTotal == this.associationForm.etape5.capitalTotal) {
            this.associationForm.etape = 5;
            this.etatButton = false;
            this.$http
              .post("/associations", this.associationForm)
              .then((result) => {
                if (result.data.success) {
                  this.etatButton = true;
                  this.showToast("Enregisrement effectué", "success");
                  this.$router.go(-1);
                } else {
                  this.etatButton = true;
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((e) => {
                this.etatButton = true;

                var err = e.response.data.errors;
              });
          } else {
            this.showToast(
              "Le total des montants des associés doit être égale au capital total",
              "danger"
            );
          }
          //   }
          // });
          break;
        case 5:
          // this.$refs.addForm.validate().then((success) => {
          //   if (success) {
          this.associationForm.etape = 6;
          this.associationForm.etape5.capitalTotal = this.totalCapitaux;

          this.makeFormat();

          this.etatButton = false;

          this.$http
            .post("/associations", this.associationForm)
            .then((result) => {
              if (result.data.success) {
                this.etatButton = true;
                this.ended = true;
                this.showToast("Enregisrement effectué", "success");
                this.$router.go(-1);
              } else {
                this.etatButton = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButton = true;

              var err = e.response.data.errors;
            });
          //     });
          // }
          break;
      }
    },

    getDevises() {
      this.$http
        .get("/devises")
        .then((response) => {
          this.devises = response.data.data;
          this.devisePrincipal = this.devises.find(
            ({ devise_principale }) => devise_principale === 1
          );
        })
        .catch((err) => {});
    },

    // getChargeursList() {
    // 	this.$http.get("/chargeurs").then((res) => {
    // 		this.chargeursList = res.data.data;
    // 	});
    // },

    /**
     * Etape1
     */

    validationFormInfoGenerale() {
      return new Promise((resolve, reject) => {
        this.$refs.infoGeneraleRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    // Associes start
    repeateAgainAssocies() {
      
      let associesUse = []
      for (let i = 0; i < this.itemsAssocies.length; i++) {
        associesUse.push(this.itemsAssocies[i].nom_associe)
      }


      this.associes.forEach((element, index) => {
        if(associesUse.includes(element.name)) {
          delete this.associes[index]
        }
        });
        

      this.showRemoveItemAssocies = this.showRemoveItemAssocies + 1;
      this.itemsAssocies.push({
        id: (this.nextTodoIdAssocies += this.nextTodoIdAssocies),
        nom_associe: "",
      });
    },

    removeItemAssocies(index) {
      this.showRemoveItemAssocies = this.showRemoveItemAssocies - 1;
      this.itemsAssocies.splice(index, 1);
      this.comptesAssocies.splice(index, 1);
      // this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },

    checkNomAssocies(index) {
      if (this.itemsAssocies[index].errorNomAssocies) {
        this.itemsAssocies[index].errorNomAssocies = "";
      }

      let enteredNomAssocies = this.itemsAssocies[index].nom_associe;

      for (let i = 0; i < this.itemsAssocies.length; i++) {
        const item = this.itemsAssocies[i];

        if (
          i !== index &&
          item.nom_associe.toUpperCase() == enteredNomAssocies.toUpperCase()
        ) {
          this.errorCheckNomAssocies[index] =
            "Ce nom est déjà choisi au niveau de la ligne " + (i + 1);
          this.disabledButtonAssocies = true;

          i = this.itemsAssocies.length;
        } else if (
          this.comptes.find(
            (_compte) =>
              _compte.libelle.toUpperCase() == enteredNomAssocies.toUpperCase()
          )
        ) {
          // this.errorCheckNomAssocies[index] =
          //   "Ce libellé est déjà enregistré pour un autre compte ";
          // this.disabledButtonAssocies = true;
        } else {
          this.disabledButtonAssocies = false;

          if (this.errorCheckNomAssocies.length) {
            this.errorCheckNomAssocies = [];
          }
        }
      }
    },

    checkAssocieCompte(index) {
      if (this.comptesAssocies[index].errorsLibelleCompteAssocies) {
        this.comptesAssocies[index].errorsLibelleCompteAssocies = "";
      }

      let enteredNomAssocies = this.comptesAssocies[index].libelle_compte;

      for (let i = 0; i < this.comptesAssocies.length; i++) {
        const item = this.comptesAssocies[i];
        if (
          i !== index &&
          item.libelle_compte.toUpperCase() == enteredNomAssocies.toUpperCase()
        ) {
          this.comptesAssocies[index].errorsLibelleCompteAssocies =
            "Ce libellé est déjà choisi au niveau de la ligne " + (i + 1);

          i = this.comptesAssocies.length;
          this.disabledButtonCompteAssocies = true;
        } else if (
          this.comptes.find(
            (_compte) =>
              _compte.libelle.toUpperCase() == enteredNomAssocies.toUpperCase()
          )
        ) {
          this.comptesAssocies[index].errorsLibelleCompteAssocies =
            "Ce libellé est déjà enregistré pour un autre compte ";
          this.disabledButtonCompteAssocies = true;
        } else {
          this.disabledButtonCompteAssocies = false;
        }
      }
    },
    // Associes end

    // Acheteur start
    repeateAgainAcheteur() {
      this.showRemoveItemAcheteur = this.showRemoveItemAcheteur + 1;
      this.itemsAcheteurs.push({
        id: (this.nextTodoIdAcheteur += this.nextTodoIdAcheteur),
        nom_acheteur: "",
      });

      this.compteAcheteurs.push({
        id: (this.nextTodoIdAcheteur += this.nextTodoIdAcheteur),
        nom_acheteur: "",
        solde_initial: 0,
      });

      // this.$nextTick(() => {
      // 	this.trAddHeight(this.$refs.row[0].offsetHeight);
      // });
    },

    removeItemAcheteur(index) {
      this.showRemoveItemAcheteur = this.showRemoveItemAcheteur - 1;
      this.itemsAcheteurs.splice(index, 1);
      this.compteAcheteurs.splice(index, 1);
    },

    checkNomAcheteur(index) {
      if (this.itemsAcheteurs[index].errorNomAcheteur) {
        this.itemsAcheteurs[index].errorNomAcheteur = "";
      }

      let enteredNomAcheteur = this.itemsAcheteurs[index].nom_acheteur;

      for (let i = 0; i < this.itemsAcheteurs.length; i++) {
        const item = this.itemsAcheteurs[i];

        if (
          i !== index &&
          item.nom_acheteur.toUpperCase() == enteredNomAcheteur.toUpperCase()
        ) {
          this.errorCheckNomAcheteur[index] =
            "Ce nom est déjà choisi au niveau de la ligne " + (i + 1);
          this.disabledButtonAcheteur = true;

          i = this.itemsAcheteurs.length;
        }

        // else if (
        //   this.comptes.find(
        //     (_compte) =>
        //       _compte.libelle.toUpperCase() == enteredNomAcheteur.toUpperCase()
        //   )
        // ) {
        //   this.errorCheckNomAcheteur[index] =
        //     "Ce libellé est déjà enregistré pour un autre compte ";
        //   this.disabledButtonAcheteur = true;
        // }
        else {
          this.disabledButtonAcheteur = false;

          if (this.errorCheckNomAcheteur.length) {
            this.errorCheckNomAcheteur = [];
          }
        }
      }

      var account = this.comptes.find(
        (_compte) => _compte.libelle == this.itemsAcheteurs[index].nom_acheteur
      );
      if (account) {
        let accDevise = this.devises.find(
          (_dev) => _dev.name == account.devise
        );
        this.compteAcheteurs[index].devise = accDevise.id;
        this.compteAcheteurs[index].deviseDisabled = true;
      } else {
        this.compteAcheteurs[index].devise = "";
        this.compteAcheteurs[index].deviseDisabled = false;
        this.$refs.compteRules.reset();
      }
    },
    // Acheteur end

    resetErrorInputNameAssociation() {
      if (this.errorsName) {
        this.errorsName = null;
      }
    },

    resetErrorInputCodeAssociation() {
      if (this.errorsCode) {
        this.errorsCode = null;
      }
    },

    /**
     * Etape2
     */

    validationFormCompte() {
      return new Promise((resolve, reject) => {
        this.$refs.compteRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    editVehicule() {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          if (
            this.editedFormVehiculeStock.vente_annulee == "OUI" ||
            !this.editedFormVehiculeStock.rap
          ) {
          } else {
            this.editedFormVehiculeStock.date_arrivee = "";
          }
          this.newdevise_achat=this.editedFormVehiculeStock.tauxA
          this.newdevise_transp=this.editedFormVehiculeStock.tauxT
          this.prixRevientItem(this.editedFormVehiculeStock)
          this.vehiculeStockList.splice(this.editedFormVehiculeStock.index, 1);
          this.vehiculeStockList.unshift(this.editedFormVehiculeStock);
          this.modalEditFormVehiculeStock = false;
        }
      });
    },

    // Compte associes start
    repeateAgainCompteAssocies() {
      this.showRemoveItemCompteAssocies = this.showRemoveItemCompteAssocies + 1;

      this.comptesAssocies.push({
        id: (this.nextTodoIdCompteAssocies += this.nextTodoIdCompteAssocies),
        libelle_compte: "",
        nom_associe: "",
        solde_initial: "",
        devise: "",
      });

      // this.$nextTick(() => {
      // 	this.trAddHeight(this.$refs.row[0].offsetHeight);
      // });
    },

    removeItemCompteAssocies(index) {
      this.showRemoveItemCompteAssocies = this.showRemoveItemCompteAssocies - 1;
      this.comptesAssocies.splice(index, 1);
      // this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    // Compte associes end

    /**
     * Etape3
     */

    validationFormDette() {
      return new Promise((resolve, reject) => {
        this.$refs.detteRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    resetErrorInputCarteImportateur() {
      // code
    },

    resetErrorInputFrais() {
      // code
    },

    /**
     * Etape4
     */

    getClients() {
      this.$http.get("/clients").then((res) => {
        this.clientsList = res.data.data;

        this.clientsList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    getMarquesList() {
      this.$http.get("/marques").then((res) => {
        this.marquesList = res.data.data.reverse();
        let currentDate = new Date();
        this.maxDate= moment(String(currentDate)).format('YYYY-MM-DD')
        this.marquesList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    getCouleursList() {
      this.$http.get("/couleurs").then((res) => {
        this.couleursList = res.data.data.reverse();

        this.couleursList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    getNavires() {
      this.$http.get("/navires").then((res) => {
        this.navires = res.data.data.reverse();
      });
    },

    validationFormVehiculeStock() {
      return new Promise((resolve, reject) => {
        this.$refs.vehiculeStockRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    setVehiculeStockIntableList() {
      this.$refs.vehiculeStockRules.validate().then((success) => {
        if (success) {
          let checkStatus = this.vehiculeStockList.find(
            (_veh) =>
              _veh.numero_chassis == this.itemVehiculeStock.numero_chassis
          );
          let checkStatus2 = this.vehicules2.find(
            (_veh) =>
              _veh.numero_chassis == this.itemVehiculeStock.numero_chassis
          );
          this.itemVehiculeStock.deviseA=this.devisesManif.devise_achat.name
          this.itemVehiculeStock.tauxA=this.devisesManif.devise_achat.value
          this.itemVehiculeStock.deviseT=this.devisesManif.devise_transport.name
          this.itemVehiculeStock.tauxT=this.devisesManif.devise_transport.value
          let date_new=this.itemVehiculeStock.date_arrivee
          if (checkStatus || checkStatus2) {
            this.showToast(
              "Ce numéro de chassis est déjà renseigné !",
              "danger"
            );

          } else {
            this.prixRevientItem(this.itemVehiculeStock);
            this.vehiculeStockList.unshift(this.itemVehiculeStock);
            this.itemVehiculeStock = {};
            this.itemVehiculeStock.boite = "Automatique";
            this.itemVehiculeStock.prix_achat = 0;
            this.itemVehiculeStock.prix_transp = 0;
            this.itemVehiculeStock.prix_carte_imp = 0;
            this.itemVehiculeStock.mtdepense = 0;
            this.itemVehiculeStock.frais = 0;
            this.itemVehiculeStock.vente_annulee = "NON";
            this.itemVehiculeStock.cylindre = "V4";
            this.itemVehiculeStock.date_arrivee = date_new;
            this.$refs.vehiculeStockRules.reset();
            this.saveCouleur()
            this.saveMarque()
          }

        }
      });
    },

    editVehiculeStockIntableList(index) {
      this.editedFormVehiculeStock = Object.assign(
        {},
        this.vehiculeStockList[index]
      );
      this.editedFormVehiculeStock.updated=1
      this.editedFormVehiculeStock.index = index;
      this.modalEditFormVehiculeStock = true;
    },

    deleteVehiculeStockIntableList(id) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des véhicules en stock ?",
          {
            title: "Supprimer un véhicule",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            if (this.vehiculeStockList[id]) {
              this.vehiculeStockList.splice(id, 1);
              this.showToast("Suppression effectuée avec succès", "success");
            }
          }
        });
    },

    resetModalVehiculeStock() {
      // this.editedForm.name = "";
      // this.editedForm.phone = "";
      // this.editedForm.work = "";
      // this.nameState = null;
      this.resetAllInputModal();
    },

    resetAllInputModal() {
      this.resetInputNameModal();
      this.resetInputPhoneModal();
      this.resetInputFonctionModal();
    },

    resetInputNameModal() {
      if (this.errorsNameModal) {
        this.errorsNameModal = null;
      }
    },

    resetInputPhoneModal() {
      if (this.errorsPhoneModal) {
        this.errorsPhoneModal = null;
      }
    },

    resetInputFonctionModal() {
      if (this.errorsFonctionModal) {
        this.errorsFonctionModal = null;
      }
    },

    /**
     * Etape5
     */

    validationFormRepartitionCapital() {
      return new Promise((resolve, reject) => {
        this.$refs.repartitionCapitalRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    /**
     * Etape6
     */

    validationFormSynthèse() {
      return new Promise((resolve, reject) => {
        this.$refs.syntheseRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";

.date-picker {
      width: 10rem !important;
    }
    
#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.input-group-append {
  .input-group-text {
    background-color: rgb(201, 201, 201);
  }
}

.block-text .card-body {
  padding-bottom: 0px;
}

.repeater-form {
  overflow: hidden;
  transition: 5s height;
}
</style>
